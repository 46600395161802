<template>
  <b-col class="page page-account-history">
    <AccountHeader>
      <template #breadcrumb>
        <Breadcrumb :items="[{label: $t('Account'), click: () => $router.push({name: 'Account'})}, {label: $t('History')}]"/>
      </template>
    </AccountHeader>
    <div class="p-3">
      <div class="logs">
        <div class="log d-flex" v-for="(log, index) in logs" :key="index">
          <img :src="log.game.iconUrl.getUrl()" class="game-icon mr-3"/>
          <div class="log-content">
            <div class="log-text">{{log.content}}</div>
            <div class="log-date">{{$toDateTimeString(log.createdAt)}}</div>
          </div>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import AccountHeader from "@/components/headers/AccountHeader";
import profileService from "@/services/profileService";
import dialogHelper from "@/helpers/dialogHelper";

export default {
  name: "History",
  components: {AccountHeader},
  data() {
    return {
      loading: false,
      logs: [],
      take: 20,
      skip: 0,
      draw: 0,
      ended: false,
      timeout: null
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.$bridge.$on("scrollToBottom", this.loadData);
  },
  beforeDestroy() {
    this.$bridge.$off("scrollToBottom", this.loadData);
  },
  methods: {
    async loadData(renew = false) {
      if (renew) {
        this.ended = false;
        this.skip = 0;
        this.logs = [];
      }
      else {
        if (this.loading || this.ended)
          return;
      }

      this.loading = true;
      this.draw++;
      const response = await profileService.getLogs({
        draw: this.draw,
        take: this.take,
        skip: this.skip
      });

      this.loading = false;
      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      const data = response.data;
      if (this.draw <= data.draw) {
        let logs = data.logs;
        const totalLogs = logs.length;
        this.logs = [...this.logs, ...logs];
        this.skip += totalLogs;
        this.ended = this.take > totalLogs;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.logs {
  .log {
    padding: 15px 0;
    border-bottom: 1px solid #f4f5f7;

    .game-icon {
      height: 44px;
      width: 44px;
      object-fit: contain;
    }

    .log-content {
      flex: 1;
      font-size: 14px;
      color: #8e8e93;

      .log-text {
        color: #1c1c1c;
      }
    }
  }
}
</style>